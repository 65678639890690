require('./1-jquery-validate.js');
require('./2-swiper.min.js');
require('./forms.js');
require('../less/index.less');
require('./working-with-us');



(function ($) {

	if (window.location.hostname.startsWith('dev-local')) {
		$('img').each(function () {
			var src = $(this).attr('src');
			if (src && src.startsWith('/cms')) {
				$(this).attr('src', 'https://jaeckledistributors.com' + src)
			}

		});
	}

	/*==========================================================
	 = Helper Functions
	/==========================================================*/

	let isTouchDevice;

	try {
		document.createEvent("TouchEvent");
		isTouchDevice = true;
	} catch (e) {
		isTouchDevice = false;
	}

	function isTablet() {
		return $(window).width() < 1025;
	}

	function isMobile() {
		return $(window).width() < 600;
	}

	function smoothScroll(id, speed, offset, callback) {
		offset = (typeof offset === "number") ? offset : 0;
		speed = (typeof speed === "number") ? speed : 800;
		$('html, body').animate({
			scrollTop: $(id).offset().top - offset
		}, speed, function () {
			window.location.hash = id;
			if(callback) callback();
		});
	}

	/*==========================================================
	 = jQuery Hover Menu Plugin
	/==========================================================*/

	$.fn.hoverMenu = function (menu, options) {

		if(menu === "destroy") {
			return this.each(function() {
				let $this = $(this);
				$this.off("mouseenter.hovermenu");
				$this.off("mouseleave.hovermenu");
			});
		}

		let openTimer, closeTimer, $menu, $trigger, $this = $(this);

		// Ensure $menu is set to a jquery instance
		if((menu instanceof jQuery)) {
			$menu = menu;
		} else {
			$menu = $(menu);
		}

		// If there are no menu instances, return
		if(!$menu.length) {
			let err = "$.hoverMenu menu argument requires a valid jQuery instance/selector";
			console.warn(err);
			return false;
		}

		// Establish default settings
		let settings = {
			cssSpeed: 0, // Speed of CSS transitions (if used)
			closeDelay: 150,
			menuHidden: true,
			noConflict: true, // Two menus can't be open at the same time
			openClass: "menu-open",
			openDelay: 150,
			onClose: null,
			onOpen: null
		};

		// Extend default settings with options (if any);
		if(typeof options === "object") {
			settings = $.extend(settings, options);
		}

		function ensureNoConnfict() {
			if(settings.noConflict) {
				let openMenus = $(`.${settings.openClass}`);
				if(openMenus.length) {
					openMenus.css("z-index", "1");
					$menu.css("z-index", "2");
				}
			}
		}

		function openMenu() {
			if(closeTimer) {
				clearTimeout(closeTimer);
				closeTimer = false;
			} else {
				openTimer = setTimeout(() => {
					ensureNoConnfict();
					$this.addClass(settings.openClass);
					$menu.addClass(settings.openClass);
					if(settings.menuHidden) {
						$menu.show();
					}
					if(settings.onOpen) settings.onOpen();
					openTimer = false;
				}, settings.openDelay);
			}
		};

		function closeMenu() {
			if(openTimer) {
				clearTimeout(openTimer);
				openTimer = false;
			} else {
				closeTimer = setTimeout(() => {
					$this.removeClass(settings.openClass);
					$menu.removeClass(settings.openClass);
					if(settings.menuHidden) {
						$menu.hide();
					}
					if(settings.onClose) settings.onClose();
					closeTimer = false;
				}, settings.closeDelay);
			}
		};

		return this.each(function() {
			let $this = $(this);
			if(!isTouchDevice) {
				$this.off("mouseenter.hovermenu").on("mouseenter.hovermenu", openMenu);
				$this.off("mouseleave.hovermenu").on("mouseleave.hovermenu", closeMenu);
				$menu.off("mouseenter.hovermenu").on("mouseenter.hovermenu", openMenu);
				$menu.off("mouseleave.hovermenu").on("mouseleave.hovermenu", closeMenu);
			} else {
				$this.off("touchstart").on("touchstart", function(e) {
					e.preventDefault();
					if($menu.hasClass(settings.openClass)) {
						closeMenu();
					} else {
						if(window.$openMenu) {
							$menuTrigger.removeClass(settings.openClass);
							$openMenu.removeClass(settings.openClass);
						}
						openMenu();
						window.$openMenu = $menu;
						window.$menuTrigger = $this;
					}
				});
			}
		});

	};

	/*==========================================================
	 = Global Hover Menus
	/==========================================================*/

	function setupMainHoverMenus() {
		if(!isTablet()) {
			$("#mainNav li").each(function() {
				let $this = $(this);
				let $menu, menuIndex = $this.data("header-menu");
				let left = $this.offset().left + ($this.width() / 2);
				let ww = $(window).width();
				if(menuIndex >= 0) {
					$(".header-menu").each(function() {
						if($(this).data("header-menu") === menuIndex) {
							$menu = $(this);
							let pos = left - ($menu.width() / 2);
							if(pos < 0) {
								pos = pos + (pos * -1);
							}
							if(pos + $menu.width() > ww) {
								pos = pos - ((pos + $menu.width()) - ww);
							}
							$menu.css("left", pos + "px");
							$this.hoverMenu($menu, {
								menuHidden: false,
								openDelay: 100,
								cssSpeed: 800
							});
						}
					});
				}
			});
		}
	};

	/*==========================================================
	 = Handle Hash Links Scrolling
	/==========================================================*/

	function handleHashLinkScrolling() {
		if (window.location.hash !== "") {
			console.log('SmoothScrolling');
			smoothScroll(window.location.hash, 10);
		}
	}

	/*==========================================================
	 = Shuffle Grid Controller
	/==========================================================*/

	let shuffleGridController = {

		makeCardHeightsEven() {
			let $cards = $("#shuffleList .text-block");
			if(!$cards.length) {
				$cards = $("#shuffleList .title-name-location");
			}
			if($cards.length) {
				$cards.css({"height": "auto"});
				let maxHeight = 0;
				$cards.each(function() {
					let $this = $(this);
					let height = $this.height();
					if(height > maxHeight) {
						maxHeight = height;
					}
				});
				$cards.css({"height": maxHeight + "px"});
			}
		},

		reset: function (resetEvents) {
			$("#shuffleList .shuffle-item").removeClass("active inactive").css({
				"transform": "translate3d(0,0,0)",
				"z-index": "1"
			});
			if(resetEvents) {
			$(".filter-nav-item, .filter-type-menu .category-nav-item")
				.off("mouseenter.hovermenu mouseleave.hovermenu");
			}
		},

		setupHoverMenus: function () {
			let $navItems = $(".filter-nav-item");
			if(isTablet()) {
				$navItems.off("click.floor-menu").on("click.floor-menu", function() {
					let $menu = $(this).find(".filter-type-menu");
					$menu.slideToggle();
					$(this).toggleClass("menu-open");
					$menu.toggleClass("menu-open");
				});
			} else {
				$navItems.each(function () {
					let $menu = $(this).find(".filter-type-menu");
					if($menu.length) {
						$(this).hoverMenu($menu, {
							menuHidden: true,
							openDelay: 50,
							noConflict: false
						});
					}
				});
			}
		},

		setupCardShuffling: function () {
			let self = this;
			let cardsPerRow = 4;
			let ww = $(window).width();
			if(ww < 600) {
				cardsPerRow = 1;
			} else if(ww < 900) {
				cardsPerRow = 2;
			} else if(ww < 1200) {
				cardsPerRow = 3;
			}
			let $cards = $("#shuffleList .shuffle-item");
			let $navItems = $(".filter-nav-item li, li.category-nav-item");
			let $grid = $("#shuffleGrid");
			let originalGridHeight = $grid.height();
			if(!$cards.length) return;

			let cardHeight = $cards.eq(0).outerHeight();
			let productTags = {};
			let cardPositions = [];
			let shuffling = false;

			$cards.each(function() {
				let $this = $(this);
				let tags = $this.data("shuffle-tags").toString();
				if(tags.indexOf(",") > -1) {
					tags = tags.split(",");
				} else {
					tags = [tags];
				}
				if(!tags) return;
				$this.initialOffset = $this.offset();
				cardPositions.push($this.offset());
				for(let i=0; i<tags.length; i++) {
					if(!productTags[tags[i]]) {
						productTags[tags[i]] = [];
					}
					productTags[tags[i]].push($this);
				}
			});

			function setTransform($el, posIndex) {
				let y = $el.initialOffset.top;
				let x = $el.initialOffset.left;
				let y2 = cardPositions[posIndex].top;
				let x2 = cardPositions[posIndex].left;
				let translate = "translate3d(" + ((x2 - x) + "px,") + ((y2 - y) + "px, 0)");
				$el.css({
					"transform": translate,
					"-ms-transform": translate,
					"-moz-transform": translate,
					"-webkit-transform": translate
				});
			};

			function shuffle(tagIds) {
				if(!tagIds || !tagIds.length) return;
				let posIndex = 0;
				let found = 0;
				let toTransform = [];
				let toReset = [];
				for(let i in productTags) {
					if(!productTags.hasOwnProperty(i)) continue;
					if(tagIds.indexOf(i) === -1) {
						for(let j=0; j<productTags[i].length; j++) {
							if(toReset.indexOf(productTags[i][j]) === -1) {
								toReset.push(productTags[i][j]);
							}
						}
					} else {
						for(let j=0; j<productTags[i].length; j++) {
							if(toTransform.indexOf(productTags[i][j]) === -1) {
								toTransform.push(productTags[i][j]);
							}
						}
					}
				}
				toReset.forEach((p) => {
					p.addClass("inactive").removeClass("active");
					p.css({
						"transform": "translate3d(0,0,0)",
						"z-index": "1"
					});
				});

				toTransform.forEach((p) => {
					setTransform(p, posIndex);
					p.addClass("active").removeClass("inactive");
					posIndex++;
				});

				let rows = Math.ceil(toTransform.length / cardsPerRow);
				let gridHeight = ((cardHeight * rows) + (60 * rows)) + "px";
				$grid.css("height", gridHeight);
			}

			function reset() {
				self.reset(false);
				$grid.css("height", originalGridHeight + "px");
			}

			$(".filter-nav-item.view-all").on("click", reset);

			$navItems.off("touchend click").on("touchend click", function() {
				if(shuffling) return;
				shuffling = true;
				let $this = $(this);
				$navItems.removeClass("active");
				$this.addClass("active");
				let tagId = $this.data("tag-id").toString();
				if(tagId === 'all') {
					reset();
				} else {
					shuffle([tagId]);
				}
				shuffling = false;
			});

		},

		init: function () {
			this.reset(true);
			this.makeCardHeightsEven();
			this.setupHoverMenus();
			this.setupCardShuffling();
		}
	}

	/*==========================================================
	 = Shuffle Grid Sorting
	/==========================================================*/

	let $wrapper = $("#modalWrapper");
	let $modal = $("#modal");

	function openModal() {
		$wrapper.fadeIn("fast");
		$modal.addClass("modal-open");
		$(window).on("scroll", function (e) {
			e.preventDefault();
		});
	};

	function closeModal() {
		$modal.off().on("transitionend", function() {
			$("#innerModal").removeClass("active");
		}).removeClass("modal-open");
		$wrapper.fadeOut("fast");
		$(window).off("scroll");
	};

	$("#shuffleGrid .shuffle-item a.blue-btn").on("click", function() {
		let modalId = $(this).data("modal-id");
		let $modalBody = $(`.body-modal[data-modal-id='${modalId}']`);
		$(".body-modal").addClass("hidden");
		$modalBody.removeClass("hidden");
		openModal();
	});

	$("#closeModal").on("click", closeModal);

	/*==========================================================
	 = Custom Events
	/==========================================================*/

	// window resize start/end
	let resizeTimer, windowResizing = false;
    $(window).on("resize", () => {
        if (!windowResizing) {
            $(window).trigger("windowResizeStart");
            windowResizing = true;
        }
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
            $(window).trigger("windowResizeEnd");
            windowResizing = false;
        }, 250);
    });

	// window scroll start/end
	let scrollTimer, windowScrolling = false;
    $(window).on("scroll", () => {
        if (!windowScrolling) {
            $(window).trigger("windowScrollStart");
            windowScrolling = true;
        }
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
            $(window).trigger("windowScrollEnd");
            windowScrolling = false;
        }, 150);
	});

	/*==========================================================
	 = Lazy Fading
	/==========================================================*/

	function isElementInViewport (el) {

		if (typeof jQuery === "function" &&
			el instanceof jQuery) {
			el = el[0];
		}

		var rect = el.getBoundingClientRect();
		let threshold = window.innerHeight * 0.6;

		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight + threshold || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth + threshold || document.documentElement.clientWidth)
		);
	}

	function setupLazyFades() {

		let $fades = $(".fade-into-viewport");

		function checkFades() {
			$fades.each(function() {
				if(isElementInViewport(this)) {
					$(this).addClass("show");
					if($fades.length === $(".fade-into-viewport.show").length) {
						$(window).off("scroll.lazyload");
					}
				}
			});
		}

		$(window).on("scroll.lazyload", function() {
			checkFades();
		});

		checkFades();
	}

	/*==========================================================
	 = Testimonial Slider
	/==========================================================*/

	function setupTestimonialSlider() {
		var slides = $(".swiper-slide");
		if(!slides.length) return;
		if(slides.length === 1) {
			$(".swiper-container").css("overflow", "visible");
		}
		var swiper = new Swiper("#testimonial-slider", {
			autoplay: 8000,
			loop: slides.length > 1,
			pagination: '.swiper-pagination',
			paginationClickable: true
		});

		$("#testimonial-slider").hover(function () {
			swiper.stopAutoplay();
		}, function () {
			swiper.startAutoplay();
		});
	}

	/*==========================================================
	= Make heights even
	/==========================================================*/

	window.makeHeightsEven = function(selector) {
		selector = selector || '.even-heights';
		let $makeEven = $(selector);
		if(!$makeEven.length) return;
		$makeEven.css('min-height', 0);
		let height = 0;
		$makeEven.each(function() {
			let $this = $(this);
			if($this.height() > height) height = $this.height();
		});
		$makeEven.css('min-height', height + 'px');
	}

	/*==========================================================
	 = Window loaded
	/==========================================================*/

	window.onload = () => {
		makeHeightsEven();
		setupMainHoverMenus();
		setupLazyFades();
		setupTestimonialSlider();
		shuffleGridController.init();
		handleHashLinkScrolling();
		$(window).on("windowResizeEnd", function() {
			setupMainHoverMenus();
			shuffleGridController.init();
			makeHeightsEven();
		});
	};

	/*==========================================================
	= Navigation Setup
	/==========================================================*/


	(function () {

		const $mobileToggle = $("#hamburgerButton");
		const $submenuToggles = $("li.has-submenu");
		const $closeButtons = $(".close-button");
		const $backButtons = $(".back-btn");
		const $mainMenu = $("#mainMenu");
		const $allMenus = $(".menu-slide-right");
		const $sublinks = $(".submenu a.link");
		const $headerLinks = $(".header-menu a");

		$mobileToggle.on("click", function () {
			$mainMenu.addClass("is-active");
		});

		$closeButtons.on("click", function (e) {
			e.stopPropagation();
			$allMenus.removeClass("is-active");
		});

		$submenuToggles.on("click", function (e) {
			e.preventDefault();
			const id = $(this).data('menu-id');
			const $sub = $('.submenu[data-menu-id=' + id + ']');
			console.log(id, $sub);
			if($sub && $sub.length) $sub.addClass("is-active");
		});

		$backButtons.on("click", function (e) {
			e.stopPropagation();
			$(this).parents(".submenu").removeClass("is-active");
		});

		$sublinks.on("click", function (e) {
			e.stopPropagation();
			const $parent = $(this).parents(".submenu");
			const urlKey = $parent.data("url-key");
			if (window.location.pathname.indexOf(urlKey) > -1) {
				$allMenus.removeClass("is-active");
				let href = $(this).attr("href");
				if (href.indexOf("#") > -1) {
					e.preventDefault();
					href = "#" + href.split("#")[1];
					smoothScroll(href, 0);
				}
			}
		});

		$headerLinks.on("click", function(e) {
			let href = $(this).attr("href");
			if (href.indexOf("#") > -1) {
				href = "#" + href.split("#")[1];
				if ($(href).length) {
					e.preventDefault();
					smoothScroll(href, 800);
				}
			}
		});

	})();

})(jQuery);