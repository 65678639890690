
function initFaqs() {
	var $faqs = $(".faq-item");
	$faqs.on("click", function() {
		$(this).toggleClass("faq-open").find(".faq-a").slideToggle("fast");
	});
	var $squares = $("#testimonials .home-squares");
	var rotate = 45;
}

$(document).ready(function() {
	initFaqs();
});