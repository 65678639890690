/*==========================================================
= Helpers
/==========================================================*/

function objectifyForm(formArray) {
  var returnArray = {};
  for (var i = 0; i < formArray.length; i++){
    returnArray[formArray[i]['name']] = formArray[i]['value'];
	}

  return returnArray;
}


function showButtonSubmitting($btn, submitting) {
	if(!$btn.length) return;
	let text = submitting ? "<i class='fa fa-spin fa-gear'></i> Submitting..." : "Submit Form";
	$btn.html(text);
	$btn.prop("disabled", submitting);
}

/*==========================================================
= Online Ordering Signup Form
/==========================================================*/

function setupOnlineOrderingForm() {
	let $form = $("#onlineOrderingSignupForm");
	let $btn = $form.find("button");
	if($form.length) {
		$form.validate({
			rules: {
				firstName: "required",
				lastName: "required",
				email: {
					required: true,
					email: true
				},
				company: "required",
				city: "required",
				state: "required",
				phone: "required",
				username: {
					required: true,
					maxlength: 10
				},
				canPlaceOrders: "required"
			},
			submitHandler(form) {
				showButtonSubmitting($btn, true);
				$.ajax({
					data: $(form).serialize(),
					method: "POST",
					url: "/forms/submitonlineorderingform",
					success: res => {
						window.location = '/thank-you?form=oo';
					},
					error: err => {
						console.log(err);
					}
				});
			}
		});
	}
}

/*==========================================================
= B2B Signup Form
/==========================================================*/

function setupb2bOrderingForm() {
	let $form = $("#b2bSignupForm");
	let $btn = $form.find("button");

	let $software = $form.find("#software");
	let $otherSoftware = $form.find("#other-software");

	$software.on('change', function(e) {
		let val = e.target.value;
		if(!val) return;

		if(val === 'Other'){
			$otherSoftware.removeClass('hide');
			return;
		}
		
		$otherSoftware.val('').addClass('hide');
	});

	if($form.length) {
		$form.validate({
			rules: {
				firstName: "required",
				lastName: "required",
				email: {
					required: true,
					email: true
				},
				company: "required",
				city: "required",
				state: "required",
				phone: "required"
			},
			submitHandler(form) {
				showButtonSubmitting($btn, true);
				let data = objectifyForm($(form).serializeArray());
				let mans = [], trans = [];
				$("#selectManufacturers input").each(function() {
					let $this = $(this);
					if($this.is(":checked")) {
						mans.push($this.val());
					}
				});
				$("#selectTransactionSets input").each(function() {
					let $this = $(this);
					if($this.is(":checked")) {
						trans.push($this.val());
					}
				});
				data.manufacturers = mans;
				data.transactionSets = trans;
				$.ajax({
					data: data,
					method: "POST",
					url: "/forms/submitb2b",
					success: res => {
						window.location = '/thank-you?form=b2b';
					},
					error: err => {
						console.log(err);
					}
				});
			}
		});
	}
}

/*============================================================
 == Customer Signup Form
/============================================================*/

function setupCustomerSignupForm() {
	let $form = $("#customerSignupForm");
	if(!$form.length) return;
	let $btn = $form.find("button");

	$form.validate({
		rules: {
			firstName: "required",
			lastName: "required",
			email: {
				required: true,
				email: true
			},
			company: "required",
			city: "required",
			state: "required",
			zip: "required",
			phone: "required"
		},
		submitHandler(form) {
			showButtonSubmitting($btn, true);
			let data = objectifyForm($(form).serializeArray());
			data.interests = [];
			$("#signupInterests input").each(function() {
				if($(this).is(":checked")) {
					data.interests.push($(this).val());
				}
			});

			$.ajax({
				data: data,
				method: "POST",
				url: "/forms/submitCustomerSignup",
				success: res => {
					window.location = '/thank-you?form=cs';
				},
				error: err => {
					console.log(err);
					showButtonSubmitting($btn, false);
				}
			});
		}
	});
}

/*==========================================================
= Customer Feedback Forms
/==========================================================*/

function setupCustomerFeedbackForm() {
	let $form = $("#customerFeedbackForm form");
	let $footerForm = $("#footerForm form");
	let options = {
		rules: {
			firstName: "required",
			lastName: "required",
			email: "required"
		},
		submitHandler: (form) => {
			$.ajax({
				data: $(form).serialize(),
				method: "POST",
				url: "/forms/submitfeedback",
				success: res => {
					window.location = '/thank-you?form=feedback';
				},
				error: err => {
					console.log(err);
				}
			});
		}
	}
	if($form.length) {
		$form.validate(options);
	}
	if($footerForm.length) {
		$footerForm.validate(options);
	}
}

/*==========================================================
= Quote Entry Form
/==========================================================*/

let QuoteEntryForm = {

	// Inputs
	init() {
		if(!$("#quoteEntryForm").length) return;

		let self = this;
		self.$form = $("#quoteEntryForm");
		self.$accountNumber = $("input#accountNumber");
		self.$accountName = $("input#accountName");
		self.$customerName = $("select#customerName");
		self.$customerEmail = $("select#customerEmail");
		self.$bidNumber = $("input#bidNumber");
		self.$jobName = $("input#jobName");
		self.$architect = $("input#architect");
		self.$city = $("input#city");
		self.$comments = $("textarea#comments");
		self.$productWrapper = $("#entryProducts");
		self.$submitBtn = $("#submitEntry");
		self.$otherUser = $("#otherUser");
		self.$otherEmail = $("#otherEmail");
		self.$emptyProductRow = $(".product-row").clone();
		self.data = {};
		self.numProducts = 1;

		// Add a very loose validator for price
		$.validator.addMethod('price', function(val) {
			return !val || /[0-9\.\,$]+/.test(val);
		}, 'Invalid Price');

		// Setup add product button
		$("#addProduct").on('click', () => self.addProduct());

		// Handle form submit button click
		self.$submitBtn.on('click', (e) => {Z
			e.preventDefault();
			self.updateForm();
			self.updateValidation();
			self.$form.submit();
		});

		self.$customerName.on('change', function(e) {
			let val = e.target.value;
			if(!val) return;

			let index = $(this).find('option:selected').index();
			self.$customerEmail.find('option').eq(index).prop('selected', true);

			if(val === 'other') {
				self.$otherUser.show();
				self.$otherEmail.show();
				self.$customerEmail.val('other');
			} else {
				self.validator.resetForm();
				self.$otherUser.hide();
				self.$otherEmail.hide();
			}
		});

		self.$customerEmail.on('change', function(e) {
			let val = e.target.value;
			if(!val) return;

			let index = $(this).find('option:selected').index();
			self.$customerName.find('option').eq(index).prop('selected', true);

			if(val === 'other') {
				self.$otherUser.show();
				self.$otherEmail.show();
				self.$customerName.val('other');
			} else {
				self.validator.resetForm();
				self.$otherUser.hide();
				self.$otherEmail.hide();
			}
		});

		// Setup validation
		self.validator = self.$form.validate(self.getValidationOptions());
	},

	addProduct() {
		let self = this;
		let $newRow = self.$emptyProductRow.clone();

		// Give each field a unique name
		$newRow.find('input, select').each(function() {
			let $field = $(this);
			let name = $field.attr('name');
			name += self.numProducts.toString();
			$field.attr('name', name);
		});

		// Hook up delete button on row
		$newRow.find('.delete-product').on('click', function() {
			$newRow.remove();
			self.numProducts--;
		});

		$newRow.appendTo(self.$productWrapper);
		self.numProducts++;
	},

	getProductData() {
		let self = this;
		let data = [];
		let $products = self.$productWrapper.find(".product-row");
		if(!$products.length) return;
		$products.each(function() {
			let $this = $(this);
			let p = {
				manufacturer: $this.find('.p-mfr').val(),
				sku: $this.find('.p-sku').val(),
				description: $this.find('.p-desc').val(),
				quantity: $this.find('.p-qty').val(),
				unit: $this.find('.p-um').val(),
				price: $this.find('.p-price').val(),
				base: $this.find('.p-base').val(),
				finishCode: $this.find('.p-code').val()
			};
			data.push(p);
		});
		return data;
	},

	submitForm(form) {
		let data = JSON.stringify(this.data);
		this.$submitBtn
			.html('<i class="fa fa-gear fa-spin"></i> Submitting...')
			.prop('disabled', true);
		$.ajax({
			data: data,
			contentType: 'application/json',
			method: "POST",
			url: "/frc-quote-request/submit",
			success: res => {
				window.location = '/thank-you?form=quote';
			},
			error: err => {
				console.log(err);
				this.$submitBtn
					.html('Submit Quote Request')
					.prop('disabled', false);
			}
		});
	},

	updateForm() {
		let self = this;
		self.data = {
			accountNumber: self.$accountNumber.val(),
			accountName: self.$accountName.val(),
			customerName: self.$customerName.val(),
			customerEmail: self.$customerEmail.val(),
			bidNumber: self.$bidNumber.val(),
			jobName: self.$jobName.val(),
			architect: self.$architect.val(),
			cityOrLocation: self.$city.val(),
			comments: self.$comments.val(),
			products: self.getProductData()
		}
		if(self.$customerName.val() === 'other') {
			self.data.customerName = self.$otherUser.val();
			self.data.customerEmail = self.$otherEmail.val();
		}
	},

	updateValidation() {
		this.$productWrapper.find('.p-desc, .p-qty').each(function() {
			$(this).rules('add', {
				required: true,
				messages: {
					required: 'Required'
				}
			});
		});
		this.$productWrapper.find('.p-price').each(function() {
			$(this).rules('add', {
				required: false,
				price: true
			})
		});
	},

	getValidationOptions() {
		let self = this;
		return {
			rules: {
				accountName: 'required',
				accountNumber: 'required',
				bidNumber: 'required',
				jobName: 'required',
				otherEmail: {
					required(el) {
						return self.$customerEmail.val() === 'other'
					},
					email: true
				},
				otherUser: {
					required(el) {
						return self.$customerName.val() === 'other'
					}
				}
			},
			submitHandler: () => self.submitForm()
		}
	}

}



/*==========================================================
= Event Info Submission Form
/==========================================================*/

$.validator.addMethod('filesize', function (value, element, param) {
	return this.optional(element) || (element.files[0].size <= param * 1000000)
}, 'File size must be less than {0} MB');

function setupEventSubmissionForm() {
	let $form = $("#eventSubmissionForm");
	let $btn = $form.find("button");

	if ($form.length) {
		$form.validate({
			rules: {
				name: "required",
				email: {
					required: true,
					email: true
				},
				phone: "required",
				photo1: {
					filesize: 5,
				},
				photo2: {
					filesize: 5,
				},
				photo3: {
					filesize: 5,
				},
				photo4: {
					filesize: 5,
				},
				'video': {
					filesize: 10,
				}
			},
			submitHandler(form) {
				showButtonSubmitting($btn, true);
				let data = new FormData($form[0]);
				$.ajax({
					data: data,
					processData: false,
					contentType: false,
					method: "POST",
					url: "/forms/submitInfo",
					success: res => {
						window.location = '/thank-you?form=MCImakeover';
					},
					error: err => {
						console.log(err);
					}
				});
			}
		});
	}
}



$(document).ready(() => {

	var isBot = false;

	if (grecaptcha) {
		grecaptcha.ready(function () {
			grecaptcha.execute('6LelZtQnAAAAAOW4siL5eNgjjvssv_HcjfijAPQE', { action: 'your_action' })
				.then(function (token) {
					var data = {
						token: token
					};

					$.ajax({
						data: data,
						method: "POST",
						url: "/forms/checkCaptcha",
						success: res => {
							isBot = res;
						},
						error: err => {
							console.log(err);
						}
					});

				});
		});
	}

	if (isBot) {
		$(".contact-form, .j-form").hide().after('<div id="botNotice" class="alert alert-danger" role="alert">Bot Detected. If you are not a bot please try refreshing the page.</div>');
	} else { 
		setupOnlineOrderingForm();
		setupb2bOrderingForm();
		setupCustomerFeedbackForm();
		setupCustomerSignupForm();
		setupEventSubmissionForm();
		QuoteEntryForm.init();
	}
});